import Login from "../pages/Auth/Login/Login";
import CreatorLogin from "../pages/Auth/Login/CreatorLogin";
import Admin from "../pages/Admin/Admin";
import Home from "../pages/Home/Home";
import ImagesPage from "../pages/ImagesPage/ImagesPage";
import Pro from "../pages/Pro/ProShare/Pro";
import ProNew from "../pages/Pro/ProShare/Pro-new";
import SharedImage from "../pages/ImagesPage/SharedImage";
import Event from "../pages/Event/Event";
import About from "../pages/About/About";
import EventSelector from "../pages/Event/EventSelector";
import EventDetails from "../pages/Event/EventDetails/EventDetails";
import Portfolio from "../pages/Portfolio/Portfolio/Portfolio";
import Fallback from "../pages/Fallback/Fallback";
import PortfolioForm from "../pages/Portfolio/PortfolioForm/PortfolioForm";
import Collab from "../pages/Collaboration/Collab";
import ImagesPageNew from "../pages/ImagesPage/ImagesPage-new";
import Invite from "../pages/Invitation/Invite";
import EventImages from "../pages/ImagesPage/EventImages";


const ROUTES=[
    // {
    //     path:"/",
    //     exact:false,
    //     protected:false,
    //     component:About
    // },
    {
        path:"/",
        exact:false,
        protected:false,
        component:Login
    },
    {
        path:"/login",
        exact:false,
        protected:false,
        component:Login
    },
    {
        path:"/login/:eventName",
        exact:false,
        protected:false,
        //component:Login
        component:Login,
    },
    {
        path:"/admin",
        exact:false,
        protected:true,
        component:Admin
    },
    {
        path:"/home",
        exact:false,
        protected:true,
        component:Home
    },
    {
        path:"/photos/:eventName/:userId",
        exact:false,
        protected:true,
        component:ImagesPage
    },
    {
        path:"/photosV1/:eventName/:userId",
        exact:false,
        protected:true,
        component:ImagesPageNew
    },
    {
        path:"/eventPhotos/:eventName",
        exact:false,
        protected:true,
        component:EventImages
    },
    {
        path:"/pro/:eventName",
        exact:false,
        protected:true,
        component:Pro
    },
    {
        path:"/proV1/:eventId",
        exact:false,
        protected:true,
        component:ProNew
    },
    {
        path:"/SharedImage/:eventName/:userId",
        exact:false,
        protected:false,
        component:SharedImage
    },
    {
        path:"/creatorLogin",
        exact:false,
        protected:true,
        component:CreatorLogin
    },
    {
        path:"/Event",
        exact:false,
        protected:true,
        component:Event,
    },
    {
        path:"/eventSelector",
        exact:false,
        protected:true,
        component:EventSelector,
    },
    {
        path:"/eventDetails/:eventName",
        exact:false,
        protected:true,
        component:EventDetails,
    },
    {
        path:"/about",
        exact:false,
        protected:true,
        component:About,
    },
    {
        path:"/portfolio",
        exact:false,
        protected:true,
        component:Portfolio,
    },
    {
        path:"/portfolio/:userName",
        exact:false,
        protected:false,
        component:Portfolio,
    },
    {
        path:"*",
        exact:false,
        protected:false,
        component:Fallback,
    },
    {
        path:"/portfolioForm",
        exact:false,
        protected:true,
        component:PortfolioForm,
    },
    {
        path:"/collab/:eventId",
        exact:false,
        protected:true,
        component:Collab,
    },
    {
        path:"/invite/:eventId",
        exact:false,
        protected:true,
        component:Invite,
    },
]

export default ROUTES