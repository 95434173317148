import React, { useState, useEffect } from 'react';
import { RiInstagramFill, RiTwitterXFill, RiFacebookFill, RiLinkedinFill, RiYoutubeFill } from 'react-icons/ri';
import styled from '@emotion/styled';
import logofull from '../../media/images/logofull.png';

const StyledFooter = styled.footer`
  // position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  transition: transform 0.3s ease;
  transform: ${props => props.visible ? 'translateY(0)' : 'translateY(100%)'};
  border-top: 1px solid #686868;
  z-index: 1000;

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 16px;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
  }

  .logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
  }

  .logo-link img {
    height: 28px;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }

  .promo-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 2px 0;
    padding: 4px 12px;
    background: linear-gradient(90deg, #1FB8F9 0%, #66D3FF 50%, #9A6AFF 75%, #1FB8F9 100%);
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: opacity 0.2s;
    text-decoration: none;
    text-align: center;
    
    &:hover {
      opacity: 0.9;
    }
    
    .promo-text {
      font-size: 12px;
      font-weight: 500;
    }
    
    .promo-code {
      font-weight: bold;
      background: rgba(255, 255, 255, 0.2);
      padding: 2px 8px;
      border-radius: 4px;
    }
  }

  .bottom-section {
    width: 100%;
    text-align: center;
  }

  .copyright-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
    color: #000;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  .text-link {
    color: #1fb8f9;
    text-decoration: none;
    transition: color 0.2s;
    white-space: nowrap;
    
    &:hover {
      color: #0000ff;
    }
  }

  .social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 4px;
    
    a {
      color: #000;
      transition: color 0.2s;
      display: flex;
      align-items: center;
      padding: 4px;
      
      &:hover {
        color: #1fb8f9;
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    .footer-container {
      padding: 4px 8px;
    }

    .logo-link img {
      height: 24px;
    }

    .promo-banner {
      padding: 3px 8px;
      gap: 4px;
      margin-right: 14px;
      
      .promo-text {
        font-size: 10px;
      }
      
      .promo-code {
        font-size: 10px;
        padding: 1px 6px;
      }
    }

    .copyright-text {
      font-size: 10px;
      
      > * {
        &:not(:last-child):after {
          content: '|';
          margin: 0 4px;
          color: #666;
        }
      }
    }

    .social-icons {
      gap: 12px;
      margin-top: 2px;
    }
  }

  @media (min-width: 769px) {
    .copyright-text {
      > * {
        &:not(:last-child):after {
          content: '|';
          color: #000;
          margin: 0 8px;
          opacity: 0.6;
        }
      }
    }
  }
`;

const Footer = () => {
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100;
      
      if (currentScrollY < lastScrollY || isAtBottom) {
        setVisible(true);
      } else {
        setVisible(false);
      }
      
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  return (
    <StyledFooter visible={visible}>
      <div className="footer-container">
        <div className="logo-section">
          <a 
            href="https://www.imageshield.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="logo-link"
          >
            <img 
              src={logofull}
              alt="ImageShield Logo"
            />
          </a>
          <a 
            href="https://www.imageshield.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="promo-banner"
          >
            <span className="promo-text">
              Protect Your Face from Deepfakes, Scams and Other Abuses | 
              Get your first month of ImageShield® for just $2.99 with promo code
            </span>
            <span className="promo-code">STEVIE</span>
          </a>
        </div>

        <div className="bottom-section">
          <div className="copyright-text">
            <span>©2024 ImageShield LLC. All rights reserved.</span>
            <span>Fairfax, VA</span>
            <a href="mailto:support@imageshield.com" className="text-link">Contact</a>
            <a href="https://www.imageshield.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-link"> Our Privacy Policy</a>
            <a href="https://www.imageshield.com/About" target="_blank" rel="noopener noreferrer" className="text-link">About Us</a>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/people/ImageShield/100064076020962/" target="_blank" rel="noopener noreferrer"><RiFacebookFill /></a>
            <a href="https://www.instagram.com/image_shield/" target="_blank" rel="noopener noreferrer"><RiInstagramFill /></a>
            <a href="https://x.com/image_shield" target="_blank" rel="noopener noreferrer"><RiTwitterXFill /></a>
            <a href="https://www.linkedin.com/company/imageshield-protection" target="_blank" rel="noopener noreferrer"><RiLinkedinFill /></a>
            <a href="https://www.youtube.com/@UseImageShield" target="_blank" rel="noopener noreferrer"><RiYoutubeFill /></a>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;