import React from 'react'
import './AppBar.css'
import { useNavigate } from 'react-router-dom';
import logofull from '../../media/images/logofull.png';


const AppBar = ({ showLogout = true }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove session storage
   localStorage.clear();

    // Navigate to the default page '/'
    navigate('/');
  };
  return (
    <div className='app-bar'>
      <div className='app-bar-logo'>
      <a 
          href="https://www.imageshield.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="logo-link"
        >
          <img
              src={logofull}
              alt="ImageShield Logo"
              style={{marginBottom: '0rem'}}
          />
          </a>
      </div>
    </div>
  )
}

export default AppBar;
