import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Typography, Box } from '@mui/material';
import styled from 'styled-components';

const colors = {
  primary: '#1fb8f9',
  secondary: '#0000ff',
  background: '#ffffff',
  text: '#000000',
  lightGray: '#f0f0f0',
  darkGray: '#666666',
};

const OTPContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: ${colors.background};
  border-radius: 1rem;
  max-width: 25rem;
  margin: 2rem auto;
  box-shadow: 0 0 15px rgba(31, 184, 249, 0.5);

  @media (max-width: 768px) {
    max-width: 100%;
    margin: 1rem;
    padding: 0.75rem;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: ${colors.text};
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: "Nunito Sans", sans-serif;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: ${colors.text};
  margin-bottom: 1.5rem;
  text-align: center;
`;

const OTPInputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

const OTPInput = styled(motion.input)`
  width: 2.5rem;
  height: 3.125rem;
  margin: 0 0.3125rem;
  text-align: center;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  background-color: ${colors.background};
  color: ${colors.text};
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 2.25rem;
    height: 2.75rem;
    font-size: 1.125rem;
  }
`;

const Button = styled(motion.button)`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${colors.text};
  border: 0.125rem solid #000000;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${colors.background};
  margin-bottom: 1rem;

  &:hover:not(:disabled) {
    opacity: 0.9;
    box-shadow: 0 0 15px rgba(31, 184, 249, 0.5);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Message = styled(motion.p)`
  font-size: 0.9rem;
  color: ${(props) => (props.success ? '#4caf50' : '#f44336')};
  margin-top: 1rem;
`;

const Timer = styled.p`
  font-size: 0.9rem;
  color: #b3b3b3;
  margin-top: 1rem;
`;

const OTPAuth = ({ phoneNumber, onVerify, onResend, otpSentTime }) => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [remainingTime, setRemainingTime] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [message, setMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRefs = useRef([]);
  const timerRef = useRef(null);
  const [isVerifying, setIsVerifying] = useState(false);


  useEffect(() => {
    if (otpSentTime) {
      const elapsedTime = Math.floor((Date.now() - otpSentTime) / 1000);
      const newRemainingTime = Math.max(180 - elapsedTime, 0);
      setRemainingTime(newRemainingTime);
      setMessage('');
      setIsDisabled(false);
      setOtp(['', '', '', '', '', '']);

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      if (newRemainingTime > 0) {
        timerRef.current = setInterval(() => {
          setRemainingTime((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(timerRef.current);
              setMessage('OTP has expired. Please request a new one.');
              setIsDisabled(true);
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [otpSentTime]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    let timer;
    if (cooldownTime > 0) {
      timer = setTimeout(() => setCooldownTime((prev) => prev - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldownTime]);

  // const handleChange = (index, value) => {
  //   if (isNaN(value)) return;
  //   const newOtp = [...otp];
  //   newOtp[index] = value;
  //   setOtp(newOtp);

  //   if (value !== '' && index < 5) {
  //     inputRefs.current[index + 1].focus();
  //   }
  // };

  const handleChange = (index, value) => {
    if (isNaN(value)) return;

    // Handle pasted content from mobile keyboard
    if (value.length > 1) {
      const pastedData = value.slice(0, 6).replace(/\D/g, '');
      if (pastedData) {
        const newOtp = Array(6).fill('');
        for (let i = 0; i < 6; i++) {
          newOtp[i] = pastedData[i] || '';
        }
        setOtp(newOtp);

        // Focus on the last filled input or the next empty one
        const nextEmptyIndex = newOtp.findIndex((val) => val === '');
        const focusIndex = nextEmptyIndex === -1 ? 5 : nextEmptyIndex;
        inputRefs.current[focusIndex]?.focus();

        // Add highlight effect
        inputRefs.current.forEach((input) => input?.classList.add('highlight'));
        setTimeout(() => {
          inputRefs.current.forEach((input) => input?.classList.remove('highlight'));
        }, 500);
        
        return;
      }
    }

    // Handle single character input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
    if (e.key === 'Enter' && otp.join('').length === 6) {
      handleVerify();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 6).replace(/\D/g, '');

    if (pastedData) {
      const newOtp = [...otp];
      for (let i = 0; i < 6; i++) {
        newOtp[i] = pastedData[i] || '';
      }
      setOtp(newOtp);

      const nextEmptyIndex = newOtp.findIndex((val) => val === '');
      const focusIndex = nextEmptyIndex === -1 ? 5 : nextEmptyIndex;
      inputRefs.current[focusIndex]?.focus();

      inputRefs.current.forEach((input) => input?.classList.add('highlight'));
      setTimeout(() => {
        inputRefs.current.forEach((input) => input?.classList.remove('highlight'));
      }, 500);
    }
  };

  const handleVerify = async () => {
    const otpString = otp.join('');
    if (otpString.length === 6) {
      if (remainingTime === 0) {
        setMessage('OTP has expired. Please request a new one.');
        setIsDisabled(true);
        return;
      }
      if (attempts < 3) {
        setIsVerifying(true);
        try {
          const result = await onVerify(otpString);
          if (result.success) {
            setMessage('OTP verified successfully!');
            setIsDisabled(true);
            clearInterval(timerRef.current);
          } else {
            setMessage(result.message || 'Incorrect OTP. Please try again.');
            setAttempts((prev) => prev + 1);
            setOtp(['', '', '', '', '', '']);
            inputRefs.current[0]?.focus();
          }
        } catch (error) {
          setMessage('Error verifying OTP. Please try again.');
          setAttempts((prev) => prev + 1);
          setOtp(['', '', '', '', '', '']);
          inputRefs.current[0]?.focus();
        } finally {
          setIsVerifying(false);
        }
      } else {
        setCooldownTime(180);
        setAttempts(0);
        setMessage('Too many attempts. Please wait before trying again.');
        setIsDisabled(true);
      } 
    }
  };

  const handleResend = () => {
    if (cooldownTime === 0 && remainingTime === 0) {
      onResend();
    }
  };

  return (
    <OTPContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Title>OTP Verification</Title>
      <Subtitle>Please enter the 6-digit OTP sent to {phoneNumber}.</Subtitle>
      <OTPInputContainer>
        {otp.map((digit, index) => (
          <OTPInput
            ref={(el) => (inputRefs.current[index] = el)}
            key={index}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            value={digit}
            maxLength={index === 0 ? 6 : 1}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
          />
        ))}
      </OTPInputContainer>
      <Button
        whileTap={{ scale: 0.95 }}
        disabled={isDisabled || otp.join('').length < 6 || isVerifying}
        onClick={handleVerify}
      >
        {isVerifying ? 'Verifying...' : 'Verify'}
      </Button>
      {message && (
        <Message
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          success={message.includes('success')}
        >
          {message}
        </Message>
      )}
      {remainingTime > 0 && <Timer>OTP expires in {remainingTime}s</Timer>}
      {cooldownTime > 0 && <Timer>Cooldown time: {cooldownTime}s</Timer>}
      <Button
        whileTap={{ scale: 0.95 }}
        disabled={cooldownTime > 0 || remainingTime > 0}
        onClick={handleResend}
      >
        Resend
      </Button>
    </OTPContainer>
  );
};

export default OTPAuth;
