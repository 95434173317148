import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Webcam from "react-webcam";
import API_UTIL from "../../../services/AuthIntereptor";
import AppBar from "../../../components/AppBar/AppBar";
import { 
  Container, Paper, Typography, Link, TextField, Button, Box, 
  Checkbox, FormControlLabel, ThemeProvider, createTheme, Grid, useMediaQuery
} from '@mui/material';
import { motion } from 'framer-motion';
import Footer from '../../../components/Footer/Footer';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Camera, Cpu, MessageCircle, QrCode } from 'lucide-react';
import OTPAuth from "../OTPAuth/OTPAuth";
import { toast } from 'react-toastify';

const colors = {
  primary: '#1fb8f9',
  secondary: '#0000ff',
  background: '#ffffff',
  text: '#000000',
  lightGray: '#f0f0f0',
  darkGray: '#666666',
};

const breakpoints = {
  xs: '480px',   // Extra small devices (phones)
  sm: '600px',   // Small devices (larger phones)
  md: '768px',   // Medium devices (tablets)
  lg: '1024px',  // Large devices (desktops)
  xl: '1200px'   // Extra large devices
};

const StyledPhoneInput = styled.div`
  .react-tel-input {
    width: 100%;
  }

  .form-control {
    width: 100% !important;
    height: 56px !important;
    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    font-size: 16px !important;
    background: ${colors.background} !important;
    color: ${colors.text} !important;
    padding-left: 50px !important;
    
    &:hover {
      border-color: rgba(0, 0, 0, 0.87) !important;
    }
    
    &:focus {
      border-color: ${colors.primary} !important;
      border-width: 2px !important;
      box-shadow: none !important;
    }

    @media (max-width: ${breakpoints.xs}) {
      height: 48px !important;
      font-size: 14px !important;
    }
  }

  .flag-dropdown {
    background: transparent !important;
    border: none !important;
    border-radius: 4px 0 0 4px !important;
    height: 56px !important;

    @media (max-width: ${breakpoints.xs}) {
      height: 48px !important;
    }
    
    &.open {
      background: transparent !important;
      border-radius: 4px 0 0 4px !important;
    }
  }

  .selected-flag {
    background: transparent !important;
    width: 45px !important;
    height: 54px !important;
    padding: 0 0 0 8px !important;
    border-radius: 4px 0 0 4px !important;

    @media (max-width: ${breakpoints.xs}) {
      height: 46px !important;
    }

    &:hover, &:focus {
      background: transparent !important;
      border-radius: 4px 0 0 4px !important;
    }
  }

  .country-list {
    border-radius: 4px !important;
    margin-top: 4px !important;
    max-height: 300px !important;
    overflow-y: auto !important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15) !important;

    .country {
      padding: 10px !important;
      color: ${colors.text};
      
      &:hover {
        background: ${colors.primary} !important;
      }
      
      &.highlight {
        background: ${colors.lightGray} !important;
      }

      .dial-code {
        color: ${colors.text} !important;
      }
    }

    .search {
      padding: 10px !important;
      
      input {
        width: 80% !important;
        padding: 8px !important;
        border: 1px solid rgba(0, 0, 0, 0.23) !important;
        border-radius: 4px !important;

        &:focus {
          border-color: ${colors.primary} !important;
          outline: none !important;
        }
      }
    }
  }

  .invalid-number-message {
    color: #d32f2f;
    font-size: 0.75rem;
    margin-top: 4px;
    margin-left: 14px;
  }
`;

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.background};
  padding: 0.75rem;
  
  @media (min-width: ${breakpoints.xs}) {
    padding: 1rem;
  }
  
  @media (min-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
  
  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
  }
`;

const LoginSection = styled(Paper)`
  padding: 1rem;
  background-color: ${colors.lightGray};
  border-radius: 8px;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 90%;
  
  @media (min-width: ${breakpoints.xs}) {
    max-width: 400px;
    padding: 1.25rem;
    margin-bottom: 2rem;
  }
  
  @media (min-width: ${breakpoints.sm}) {
    padding: 1.5rem;
    border-radius: 10px;
  }
  
  @media (min-width: ${breakpoints.md}) {
    padding: 2rem;
    margin-bottom: 3rem;
  }

  img.logo {
    max-width: 80%;
    height: auto;
    margin: 0 auto 0.75rem;
    
    @media (min-width: ${breakpoints.xs}) {
      max-width: 85%;
      margin-bottom: 1rem;
    }
    
    @media (min-width: ${breakpoints.sm}) {
      max-width: 90%;
      margin-bottom: 1.25rem;
    }
  }
`;

const createResponsiveTheme = (isMobile) => createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    text: { primary: colors.text },
    background: { default: colors.background, paper: colors.lightGray },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: isMobile ? '1.5rem' : '2.5rem',
          '@media (min-width: 480px)': {
            fontSize: '1.75rem',
          },
          '@media (min-width: 600px)': {
            fontSize: '2rem',
          },
          '@media (min-width: 768px)': {
            fontSize: '2.5rem',
          },
        },
        h6: {
          fontSize: isMobile ? '0.875rem' : '1.25rem',
          '@media (min-width: 480px)': {
            fontSize: '1rem',
          },
          '@media (min-width: 600px)': {
            fontSize: '1.125rem',
          },
        },
        body2: {
          fontSize: isMobile ? '0.75rem' : '1rem',
          '@media (min-width: 480px)': {
            fontSize: '0.875rem',
          },
          '@media (min-width: 600px)': {
            fontSize: '0.9375rem',
          },
        },
      },
    },
  },
});

const FeatureGrid = styled(Grid)`
  margin-top: 2rem;
  padding: 0 0.5rem;
  
  @media (min-width: ${breakpoints.xs}) {
    margin-top: 2.5rem;
    padding: 0 1rem;
  }
  
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 3rem;
    padding: 0 1.5rem;
  }
  
  @media (min-width: ${breakpoints.md}) {
    margin-top: 4rem;
    padding: 0 2rem;
  }
`;

const FeatureIcon = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  border-radius: 16px;
  background: ${colors.background};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

const ResponsiveButton = styled(Button)`
  height: 40px;
  font-size: 14px;
  margin-bottom: 0.5rem;
  
  @media (min-width: ${breakpoints.xs}) {
    height: 44px;
    font-size: 15px;
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: ${breakpoints.sm}) {
    height: 48px;
    font-size: 16px;
    margin-bottom: 1rem;
  }
`;

const ResponsiveCheckbox = styled(Checkbox)`
  padding: 4px;
  @media (max-width: 600px) {
    padding: 2px;
  }
`;

function Login({ name, onLoginSuccess, showAppBar = false }) {
  const { eventName } = useParams();
  const [isNewUser, setIsNewUser] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const isToastDisp = useRef(false);
  const [isCaptureEnabled, setIsCaptureEnabled] = useState(true);
  const [otpSentTime, setOtpSentTime] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [fullPhoneValue, setFullPhoneValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = createResponsiveTheme(isMobile);

  const videoConstraints = {
    width: isMobile ? 280 : 350,
    height: isMobile ? 200 : 250,
    facingMode: "user",
  };

  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userPhoneNumber = localStorage.getItem("userPhoneNumber");
    if (userPhoneNumber && !location.state?.from?.pathname?.includes('/photos')) {
      const url = location.state?.from?.pathname 
      ? location.state.from.pathname 
      : (eventName ? `/photosv1/${eventName}/upcoming` : "/event");
      navigate(url);
    }
  }, [navigate, location.state]);


  useEffect(() => {
    if (isToastDisp.current) return;
    isToastDisp.current = true;
  }, []);

  const fromUrl = location.state?.from?.pathname 
  ? location.state.from.pathname 
  : (eventName ? `/photosv1/${eventName}/upcoming` : "/event");

  const capture = async () => {
    if (isCaptureEnabled) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
  };

  const retake = () => {
    setImgSrc(null);
    setIsCaptureEnabled(true);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const fullPhoneNumber = countryCode + phoneNumber;
  //   try {
  //     const response = await API_UTIL.post('/initiateOTP', { phoneNumber: fullPhoneNumber });
  //     if (response.status === 200) {
  //       setShowOTP(true);
  //       setOtpSentTime(Date.now());
  //     }
  //   } catch (error) {
  //     console.error("Error sending OTP:", error);
  //     // Handle error (e.g., show error message to user)
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const fullPhoneNumber = countryCode + phoneNumber;
    
    // Your existing submit logic here
    try {
      API_UTIL.post('/initiateOTP', { phoneNumber: fullPhoneNumber })
        .then(response => {
          if (response.status === 200) {
            setShowOTP(true);
            setOtpSentTime(Date.now());
          }
        });
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const createOrLoginUser = async (fullPhoneNumber) => {
    let userSource = "ImageShieldPhotoShare";
    let role = "user";
    try {
      const response = await API_UTIL.post(`/createUser`, {
        username: fullPhoneNumber,
        eventName: eventName,
        userSource: userSource,
        role: role
      });
      setIsPhoneNumberValid(true);
      if (response.status === 201) {
        setIsNewUser(true);
      } else if (response.status === 200) {
        localStorage.setItem('userPhoneNumber', fullPhoneNumber);
        handleNavigation(fullPhoneNumber);
      }
    } catch (error) {
      console.error("Error creating/logging in user:", error);
    }
  };

  const handleOTPVerify = async (otpString) => {
    const fullPhoneNumber = countryCode + phoneNumber;
    const login_platform = 'ImageShield';
    
    try {
      const response = await API_UTIL.post('/verifyOTP', { 
        phoneNumber: fullPhoneNumber, 
        otp: otpString, 
        login_platform 
      });
  
      // Check the success flag from response
      if (response.data.success === false) {
        return { 
          success: false, 
          message: response.data.message,
          remainingAttempts: response.data.remainingAttempts 
        };
      }
  
      // If success
      await createOrLoginUser(fullPhoneNumber);
      setShowOTP(false);
      return { success: true };
    } catch (error) {
      console.error("Error verifying OTP:", error);
      return { 
        success: false, 
        message: error.response?.data?.message || 'Error verifying OTP'
      };
    }
  };

  const handleOTPResend = async () => {
    const fullPhoneNumber = countryCode + phoneNumber;
    try {
      const response = await API_UTIL.post('/initiateOTP', { phoneNumber: fullPhoneNumber });
      if (response.status === 200) {
        setShowOTP(true);
        setOtpSentTime(Date.now()); // Update the time when OTP was resent
      }
      // Optionally, show a success message to the user
    } catch (error) {
      console.error("Error resending OTP:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleNavigation = async (fullPhoneNumber) => {
    if (typeof fromUrl === 'string' && fromUrl.includes("photos")) {
      try {
        const urlArray = fromUrl.split("/");
        const response = await API_UTIL.post(`/userIdPhoneNumberMapping`, {
          phoneNumber: fullPhoneNumber,
          eventName: urlArray[urlArray.length - 2],
          userId: urlArray[urlArray.length - 1],
        });
        if (response.status === 200) {
          localStorage.setItem('userPhoneNumber', fullPhoneNumber);
          navigate(fromUrl);
        }
      } catch (error) {
        console.log("Error in mapping the userId and phone number");
      }
    } else {
      navigate(fromUrl);
    }
    localStorage.setItem('userPhoneNumber', fullPhoneNumber);
  };


const handleKeyDown = (event) => {
  if (event.key === 'Enter' || event.keyCode === 13) {
    event.preventDefault();
    if (phoneNumber.length === 10) {
      handleSubmit(event);
    }
  }
};

  const uploadPhoto = async () => {
    if (termsAccepted) {
      const fullPhoneNumber = countryCode + phoneNumber;
      const formData = new FormData();
      formData.append("image", imgSrc);
      formData.append("username", fullPhoneNumber);
      formData.append("event",eventName);
      setIsSubmitting(true);

      try {
        await API_UTIL.post(`/uploadUserPotrait`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        localStorage.setItem('userPhoneNumber', fullPhoneNumber);
        handleNavigation(fullPhoneNumber);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
      finally{
        setIsSubmitting(false);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledContainer maxWidth={false}>
        {showAppBar && <AppBar showLogout={false} />}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          <LoginSection>
            <img
              src="https://www.imageshield.com/hs-fs/hubfs/Imageshield%20New%20Website%20Feb%202023/images/imageshield_full_logo_color.png?height=56&name=imageshield_full_logo_color.png"
              alt="ImageShield Logo"
              style={{
                display: 'block',
                margin: '0 auto 1rem',
                maxWidth: '100%',
                height: 'auto',
              }}
            />

            {!isPhoneNumberValid && !showOTP ? (
              <form onSubmit={handleSubmit}>
                <StyledPhoneInput>
                  <PhoneInput
                    country={'us'}
                    value={fullPhoneValue}
                    onChange={(value, data) => {
                      // Get the input element
                      const inputElement = document.querySelector('.react-tel-input input');
                      const selectionStart = inputElement?.selectionStart || 0;
                      const selectionEnd = inputElement?.selectionEnd || 0;
                      const isSelected = selectionEnd - selectionStart > 0;
                      
                      // Handle selection deletion or replacement
                      if (isSelected) {
                        const beforeSelection = value.slice(0, data.dialCode.length + selectionStart);
                        const afterSelection = value.slice(data.dialCode.length + selectionEnd);
                        const newValue = beforeSelection + afterSelection;
                        value = newValue;
                      }

                      setFullPhoneValue(value);
                      
                      // Only update country code when explicitly changed via dropdown
                      if (value.length <= data.dialCode.length) {
                        setCountryCode('+' + data.dialCode);
                      }
                      
                      // Extract the phone number without country code
                      const numberWithoutCode = value.slice(data.dialCode.length).replace(/\D/g, '');
                      setPhoneNumber(numberWithoutCode);
                      
                      // Validate the phone number
                      if (!numberWithoutCode) {
                        setPhoneNumberError("Phone number is required");
                      } else if (numberWithoutCode.length !== 10) {
                        setPhoneNumberError("Please enter a valid 10-digit phone number");
                      } else {
                        setPhoneNumberError("");
                      }
                    }}
                    onCountryChange={(countryCode, data) => {
                      setCountryCode('+' + data.dialCode);
                      // Preserve the phone number when changing country
                      setFullPhoneValue(data.dialCode + phoneNumber);
                    }}
                    enableSearch
                    searchPlaceholder="Search countries..."
                    inputProps={{
                      name: 'phone',
                      required: true,
                      placeholder: 'Phone Number',
                      onKeyDown: handleKeyDown
                    }}
                    specialLabel=""
                    countryCodeEditable={false}
                    disableCountryCode={false}
                    preserveOrder={['onCountryChange', 'onChange']}
                  />
                  {phoneNumberError && (
                    <div className="invalid-number-message">
                      {phoneNumberError}
                    </div>
                  )}
                </StyledPhoneInput>
                <ResponsiveButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
                  disabled={phoneNumber.length !== 10}
                >
                  Continue
                </ResponsiveButton>
              </form>
            ) : null}

            {showOTP && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <OTPAuth 
                  phoneNumber={countryCode + phoneNumber}
                  onVerify={handleOTPVerify}
                  onResend={handleOTPResend}
                  otpSentTime={otpSentTime}
                />
              </motion.div>
            )}

            {isNewUser && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Box>
                  <Typography variant="body2" gutterBottom>
                    Perfect lighting, perfect you — let's snap that selfie! :)
                  </Typography>
                  {imgSrc ? (
                    <img 
                      src={imgSrc} 
                      alt="captured" 
                      style={{
                        width: '100%', 
                        maxWidth: '350px', 
                        margin: '0 auto', 
                        display: 'block',
                        borderRadius: '12px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      }}
                    />
                  ) : (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                      mirrored={true}
                      style={{
                        width: '100%', 
                        maxWidth: '350px', 
                        margin: '0 auto', 
                        display: 'block', 
                        zIndex: 0,
                        borderRadius: '12px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      }}
                    />
                  )}
                  {imgSrc ? (
                    <Box>
                      <ResponsiveButton 
                        onClick={uploadPhoto} 
                        fullWidth 
                        variant="contained" 
                        sx={{
                          mt: 2,
                          bgcolor: colors.primary,
                          '&:hover': {
                            bgcolor: colors.secondary,
                          }
                        }}
                      >
                         {isSubmitting ? 'Submitting...' : 'Submit Photo'}
                      </ResponsiveButton>
                      <ResponsiveButton 
                        onClick={retake} 
                        fullWidth 
                        variant="outlined" 
                        sx={{
                          mt: 1,
                          color: colors.primary,
                          borderColor: colors.primary,
                          '&:hover': {
                            borderColor: colors.secondary,
                            color: colors.secondary,
                          }
                        }}
                      >
                        Retake photo
                      </ResponsiveButton>
                      <FormControlLabel
                        control={
                          <ResponsiveCheckbox
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            sx={{
                              color: colors.primary,
                              '&.Mui-checked': {
                                color: colors.primary,
                              }
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Accept{' '}
                            <a 
                              href="/TermsAndConditions" 
                              target="_blank" 
                              rel="noopener noreferrer"
                              style={{ 
                                color: colors.primary,
                                textDecoration: 'none',
                                '&:hover': {
                                  textDecoration: 'underline',
                                }
                              }}
                            >
                              terms and conditions
                            </a>
                          </Typography>
                        }
                        sx={{ mt: 1 }}
                      />
                    </Box>
                  ) : (
                    isCaptureEnabled && (
                      <ResponsiveButton 
                        onClick={capture} 
                        fullWidth 
                        variant="contained" 
                        sx={{
                          mt: 2,
                          bgcolor: colors.primary,
                          '&:hover': {
                            bgcolor: colors.secondary,
                          }
                        }}
                      >
                        Capture photo
                      </ResponsiveButton>
                    )
                  )}
                </Box>
              </motion.div>
            )}
              <Typography variant="body2" color={colors.text} textAlign="center" mt={2}>
                By continuing, you agree to our<br />
                <Link 
                  href="https://www.imageshield.com/terms-and-conditions" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  component="a"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.imageshield.com/terms-and-conditions', '_blank');
                  }}
                >
                  Terms and Conditions
                </Link>
                {' '}and{' '}
                <Link 
                  href="https://www.imageshield.com/privacy-policy" 
                  sx={{ 
                    color: '#1fb8f9',
                    '&:hover': {
                      color: '#0000ff'
                    }
                  }}
                  target="_blank" 
                  rel="noopener noreferrer"
                  component="a"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.imageshield.com/privacy-policy', '_blank');
                  }}
                >
                  Privacy Policy
                </Link>.
              </Typography>
          </LoginSection>
        </motion.div>

        <FeatureGrid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h2" textAlign="center" color={colors.primary}>
              How it works?
            </Typography>
          </Grid>

          {[
            {
              icon: <QrCode size={isMobile ? 36 : 48} color={colors.primary} />,
              title: "Scan QR Code",
              description: "Scan QR code at the event location for quick access"
            },
            {
              icon: <Camera size={isMobile ? 36 : 48} color={colors.primary} />,
              title: "Take a Selfie",
              description: "Click a selfie to help us identify you in event photos"
            },
            {
              icon: <Cpu size={isMobile ? 36 : 48} color={colors.primary} />,
              title: "AI Recognition",
              description: "Our AI identifies you with 99% accuracy in all photos"
            },
            {
              icon: <MessageCircle size={isMobile ? 36 : 48} color={colors.primary} />,
              title: "Get Your Photos",
              description: "Receive photos directly on your registered number"
            }
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FeatureIcon
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                {feature.icon}
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color={colors.darkGray}>
                  {feature.description}
                </Typography>
              </FeatureIcon>
            </Grid>
          ))}
        </FeatureGrid>
      </StyledContainer>
      <Footer />
    </ThemeProvider>
  );
}
export default Login;