import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API_UTIL from '../../../services/AuthIntereptor';
// import { Alert, AlertDescription } from '@/components/ui/alert';
// import { Button } from '@/components/ui/button';
// import { Input } from '@/components/ui/input';
import { Button, Input, Alert, AlertTitle} from '@mui/material';
import { motion } from 'framer-motion';

const CreatorLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await API_UTIL.post('/creator/login', {
        username: email,
        password: password
      });

      if (response.data.token) {
        localStorage.setItem('creatorToken', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('userRole', 'creator');
        
        // Update the Authorization header for future requests
        API_UTIL.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        
        navigate('/event');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col justify-center items-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="w-full max-w-md"
      >
        <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
          <div className="mb-8 text-center">
            <img
              src="/imageshield-logo.png"
              alt="ImageShield Logo"
              className="h-12 mx-auto mb-4"
            />
            <h2 className="text-2xl font-semibold text-[#1fb8f9]">Creator Login</h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full"
                required
              />
            </div>
            
            <div>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full"
                required
              />
            </div>

            {error && (
              <Alert variant="destructive">
                <AlertTitle>{error}</AlertTitle>
              </Alert>
            )}

            <Button 
              type="submit" 
              className="w-full bg-[#1fb8f9] hover:bg-[#0000ff]"
              disabled={loading}
            >
              {loading ? 'Signing in...' : 'Sign In'}
            </Button>
          </form>

          <div className="mt-4 text-center">
            <Button
              variant="ghost"
              onClick={() => navigate('/login')}
              className="text-[#666666] hover:text-[#1fb8f9]"
            >
              Login as User Instead
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CreatorLogin;